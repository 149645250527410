.summary-container {
  background-color: #f5f5f5;
}

.header {
  background-color: #434343;
  color: white;
  padding: 3px 5px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
}

.table-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  background-color: #efefef;

  th {
    padding: 3px 5px;
  }
}

.table-body {
  td {
    padding: 2px 5px;
  }

  .tags {
    max-width: 200px;
  }
}

.tag {
  display: inline-block;
  background-color: lightgray;
  padding: 1px 5px;
  margin: 1px 2px 0px 0px;
  border-radius: 15px;
}

.table-body:nth-child(odd) {
  background-color: #efefef;
}

.view-btn {
  font-size: 12px;
  font-weight: bold;
  padding: 0px 10px;
  border-radius: 10px;
}

.template-plan-edit-container {
  margin-left: 30px;
}

@include media-breakpoint-down(md) {
  .summary-container {
    font-size: 14px;

    Button {
      font-size: 8px;
    }
  }
}
