.form-container {
  .field {
    border-color: lightgray;
    width: 100%;
    border-radius: 5px;
    height: 38px;
    padding-left: 9px;
  }
  .field:focus {
    box-shadow: 0px 0px 0px 4.2px rgba(#b01212, 0.25);
    border-color: rgba(#b01212, 0.4);
    outline: 0;
  }
}

.datetime:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}

.datetime {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}
