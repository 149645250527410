@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import "./custom";
@import "./components/button/MesoMicroButton.scss";
@import "./components/client/Client.scss";
@import "./components/exercises/exercises";
@import "./components/top-navbar/TopNavbar.scss";
@import "./components/training-history-microcycle/TrainingHistoryMicrocycle.scss";
@import "./components/training-plan/TrainingPlan.scss";
@import "./components/training-plan-db/ProgramDatabase.scss";
@import "./components/training-plan/mesocycle/Mesocycle.scss";
@import "./components/training-plan/microcycle/Microcycle.scss";
@import "./components/training-plan-board/TrainingPlanBoard.scss";
@import "./components/training-plan-db/programModals/SummaryModal.scss";
@import "./components/training-plan-db/TrainingPalnDb.scss";
@import "./components/micro-summary-table/MicroSummaryTable.scss";
@import "./components/day-detail/DayDetail.scss";
@import "./components/training-plan-db/programModals/ProgramModal.scss";
@import "./components/training-plan-db/programModals/LoadModal.scss";
@import "./components/dynamic-select-form/DynamicSelectForm.scss";
@import "./components/training-plan/microcycle/HistoryTable.scss";
@import "./components/sidebar/Sidebar.scss";

h1 {
  color: $primary;
  font-weight: bold;
}

.anton-font {
  font-family: "Anton", sans-serif;
  font-weight: lighter;
}

.montserrat-font {
  font-family: "Montserrat", sans-serif;
}

.profile-pic {
  border-radius: 25px;
}

.tab-user-list {
  width: 400px;
  overflow-y: scroll;
  height: 75vh;
}

.tab-user-content {
  margin-left: 27px;
  margin-right: 27px;
  width: auto;

  & .card-body {
    background: #fcf5f5;
    font-size: 1.2rem;
    word-wrap: normal !important;
  }

  & .col {
    padding-bottom: 10px;
  }
}

.form-button {
  width: 49%;
}

.placeholder-text {
  font-style: italic;
  color: #595959;
}

.amber-text {
  color: #ffbf00;
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #00ff00;
}
