.no-exercise {
    text-align: center;
}

.name-td {
    width: 300px;
}

.type-td {
    width: 100px;
}

.movement-type-td {
    width: 150px;
}

.primary-muscle-td {
    width: 150px;
}

.secondary-muscle-td {
    width: 250px;
}

.lateral-type-td {
    width: 110px;
}

.e1rm-td {
    width: 50px;
}

.description-td {
    width: auto;
}

.youtube-td {
    width: 75px;
    text-align: center;
}

.edit-button-td {
    width: 60px;
}

.remove-button-td {
    width: 60px;
}
