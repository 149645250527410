.micro-with-day {
  display: flex;
  overflow: auto;

  .micro-container {
    position: sticky;
    left: 0;
    display: flex;
    min-width: 250px;
    background-color: white;
    z-index: 1;
    padding: 12px 5px 10px 15px;
  }
}

.phase-info-group {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;

  .phase-info-left {
    width: 48%;
    padding: 20px;

    .phase-info {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .phase-info-first-line {
      display: flex;
      justify-content: space-between;
    }
  }

  .divide {
    border-left: 1px solid #555;
    min-height: 150px;
  }

  .phase-info-right {
    width: 50%;
    padding: 20px;
  }
}

// -- Scroll bar -- //
.micro-with-day::-webkit-scrollbar {
  height: 10px;
}

.micro-with-day::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.micro-with-day::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.micro-with-day::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.summary-modal-body::-webkit-scrollbar {
  width: 12px;
}

.summary-modal-body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.summary-modal-body::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.summary-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
