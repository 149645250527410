.font-montserrat {
  z-index: 2;
}

.welcome {
  font-weight: bold;
  padding-top: 12px;
}

.logout {
  background-color: $primary;
  padding: 5px;
  border-radius: 5px;
}

.logout:hover {
  color: $secondary !important;
}
