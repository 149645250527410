.day-detail-container {
  margin-right: 8px;
  width: 250px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #5f5e5e;

  .header-in-day-detail {
    font-size: 15px;
    padding: 5px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .detail-page {
    background-color: white;
    margin: 0px 5px 5px;
    height: 250px;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .detail-content {
    padding: 3px;
  }
}

.exercise-name {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
}

.exercise-name:hover {
  white-space: normal;
}

.sets {
  text-align: center;
}

// -- Scroll bar -- //
.detail-page::-webkit-scrollbar {
  width: 5px;
}

.detail-page::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.detail-page::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.detail-page::-webkit-scrollbar-thumb:hover {
  background: #555;
}
