@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
.ctn {
  font-family: "Roboto", sans-serif;
  z-index: -1;
}

.name-list {
  background-color: #d9d9d9;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
}

.main-container {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
}

.main-column {
  background-color: $shadow;
  width: 256px;
  margin-right: 5px;
  margin-top: 10px;
  height: 400px;
  padding: 5px;

  //Phase column
  .phases-scroll {
    height: 340px;
    overflow-y: auto;
    margin-top: 10px;
  }

  .phases {
    margin-top: 10px;

    .phase-header {
      display: flex;
      justify-content: space-between;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: #595959;
      padding: 4px 10px 0px 2px;
    }

    .phase-name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 10px;
      cursor: pointer;
    }

    .phase-name.selected.phase {
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      color: white;
    }

    .phase-name.selected.phase.non-focus {
      background-color: $grey;
      color: black;
    }

    .phase-name.selected.micro {
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      color: white;
    }

    .phase-name .active-heart {
      color: $primary;
    }

    .phase-name.selected.micro .active-heart {
      color: black;
    }
  }

  //Summary column
  .summary {
    display: flex;
    flex-direction: column;
    padding: 5px 2px 5px 5px;
    position: relative;

    .column-name {
      width: 96px;
      font-weight: bold;
      vertical-align: top;
    }

    .phase-info {
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0 5px;
    }

    .edit-phase-btn {
      padding: 4px 6px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }

    .view-micro-btn {
      padding: 5px 8px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      color: #fff;
      background-color: $primary;
      border-radius: 5px;
    }
  }
}

.plan-header-ctn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #d9d9d9;
  border-radius: 5px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 3px;

  .header-btn {
    padding: 4px 6px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin: 2px 0px;
  }
}

.summary-column {
  height: 400px;
  width: 256px;
  margin-top: 10px;
}

.phases-scroll::-webkit-scrollbar {
  width: 8px;
}

.phases-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.phases-scroll::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 8px;
}

.phases-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.day-summary-board {
  width: 99% !important;
}
