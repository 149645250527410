.micro-summary-container {
  background-color: $secondary;
  padding-bottom: 1px;

  .summary-page {
    background-color: white;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary-content {
    table {
      border-collapse: separate;
      border-spacing: 15px 0;
    }
  }

  .spacer {
    height: 30px;
  }
}

.micro-summary-container.summary-in-modal {
  height: 290px;

  .summary-page {
    height: 245px;
    width: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;
  }

  .subtable-header {
    font-size: 15px;
    padding: 5px;
  }

  // -- Scroll bar -- //
  .summary-page::-webkit-scrollbar {
    width: 5px;
  }

  .summary-page::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  .summary-page::-webkit-scrollbar-thumb {
    background: rgba(191, 188, 188, 0.4);
    border-radius: 5px;
  }

  .summary-page::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.micro-summary-container.summary-in-micro {
  .delete-button {
    margin-bottom: 10px;
  }
}

.micro-summary-container.summary-in-planboard {
  max-height: 400px;
  overflow: hidden;

  .summary-page {
    max-height: 345px;
    overflow-y: auto;
  }

  // -- Scroll bar -- //
  .summary-page::-webkit-scrollbar {
    width: 5px;
  }

  .summary-page::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  .summary-page::-webkit-scrollbar-thumb {
    background: rgba(191, 188, 188, 0.4);
    border-radius: 5px;
  }

  .summary-page::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
