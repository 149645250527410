.main-history-container {
  display: flex;
  background-color: $shadow;
  margin: 8px 0px;
  height: 140px;
  border-radius: 5px;
}

.select-form-container {
  width: 260px;
}

.history-container {
  background-color: $shadow;
  border-radius: 5px;
  overflow: overlay;
  display: flex;
  height: 140px;
  position: relative;
  width: 100%;
}

table {
  width: 100%;

  thead {
    .history-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      font-weight: bold;
      padding: 2px 4px;
      background-color: $secondary;

      .header-content {
        font-size: 12px;
      }

      .toggle-btn {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}

.select-table {
  min-width: 260px;
  width: 270px;
  margin-right: 3px;
  position: sticky;
  left: 0;
  background-color: $shadow;
  display: flex;

  .select-area {
    min-width: 245px;
    width: 255px;
    border-radius: 5px;
    overflow: hidden;
  }
}

.exercise-history-table {
  min-width: 180px;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 3px;
  text-align: center;
  flex: 1;

  .header-content {
    color: white;
    font-weight: bold;
    padding: 2px 4px;
    background-color: $secondary;
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .history-subheader {
    font-size: 11px;
    background-color: gray;
    color: white;
  }

  .worklogs-content {
    font-size: 11px;
    font-weight: bold;

    td {
      width: 50px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    tr:last-child {
      border-bottom: 1px solid black;
    }
  }

  .no-training-result {
    font-weight: 400;
    padding: 15px 5px;
    text-align: center;
    width: 100%;
    font-size: 12px;
  }

  .e1rm-vl {
    height: 10px;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
  }

  .comment-box {
    height: 30px;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    margin-top: 10px;
  }
}

.history-input {
  width: 200px;
  margin-top: 5px;
}

.no-history-container {
  margin: 8px 0px;
  border-radius: 5px;
  overflow: auto;
}

.submit-btn {
  display: flex;
  margin-top: 62px;
  margin-left: 5px;
  padding: 1px 4px;
  height: 26px;
  align-items: center;
  justify-content: center;
}

.scroll-bar {
  overflow-y: overlay;
  max-height: 120px;
  position: relative;

  thead {
    position: sticky;
    top: 0;
  }
}

// -- Scroll bar -- //
.history-container::-webkit-scrollbar {
  height: 8px;
  width: 6px;
}

.history-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.history-container::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.history-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
