.client-content-container {
  margin: 0;
  padding: 0;
}

.client-content {
  margin-left: 50px;
  margin-right: 0;
  width: 96%;
  padding-right: 0;
  transition: margin-left 0.3s linear;
}

.client-content.sidebar-open {
  margin-left: 310px;
  padding-right: 0;
  // width: 79svw;
  transition: margin-left 0.3s linear;
}

@include media-breakpoint-up(lg) {
  .client-meso-micro-view-button {
    width: 288px;
  }

  .client-content {
    padding-left: 20px;
  }
}
