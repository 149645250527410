.training-history-th {
  column-width: 100px;
  text-align: center;
}

.training-history-td {
  column-width: 100px;
}

.training-history-table {
  padding: 15px;
  background: #fcf5f5;
  table-layout: fixed !important;
}

.training-history-tableheading {
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
