.sidebar {
  left: -260px;
  min-height: 100vh;
  width: 300px;
  transition: left 0.3s linear;
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 1;
}

.sidebar-toggle {
  position: absolute;
  top: 12%;
  right: -15px;
  height: 30px;
  width: 30px;
  z-index: 1;
  color: black;
  background-color: white;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}

.content {
  position: absolute;
  top: 8%;
  left: -280px;
  min-height: 100vh;
  transition: left 0.3s linear;
  padding-left: 15px;
}

.sidebar.open {
  left: 0;
}

.content.open {
  left: 0;
}

.sidebar-nav {
  color: #b01212;
  padding: 6px auto;
  font-weight: bold;
  font-size: large;
}

//profile section
.profile-table {
  color: white;
  margin: 10px 0;
}

.change-coach-btn {
  font-weight: bold;
  background-color: gray;
  border-radius: 20px;
  width: 130px;
  margin-bottom: 40px;
}
