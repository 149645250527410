.font-anton {
    font-family: "Anton", sans-serif;
}
.font-montserrat {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.new-plan__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}

.new-plan__control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #b01212;
    display: block;
}

.new-plan__control input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 20rem;
    max-width: 100%;
}

.new-plan__actions {
    text-align: right;
}

.training-plan-card-container {
    padding-left: 12px;
}

.training-plan-title {
    margin-left: 15%;
}

.training-plan-card {
    width: auto;
    color: white;
    text-decoration: none;
}

.training-plan-card:hover {
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.training-plan-active-card {
    background-color: #b01312;
}

@include media-breakpoint-up(sm) {
    .training-plan-title {
        margin-left: 20%;
    }
}

@include media-breakpoint-up(md) {
    .training-plan-title {
        margin-left: 25%;
    }
}

@include media-breakpoint-up(lg) {
    .training-plan-title {
        margin-left: 30%;
    }
}
