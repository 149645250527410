.microcycle {
  position: relative;
}

.microcycle-select-button {
  position: absolute;
  font-size: x-large;
  font-weight: bold;
  color: $primary;
  cursor: pointer;
  padding: 0 5px;
}

.left-btn {
  top: 40px;
  left: -22px;
}

.right-btn {
  top: 40px;
  right: 9px;
}

.microcycle-container {
  margin-right: 30px;
}

.microcycle-header-group {
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  padding: 5px 10px;
}

.round {
  border-radius: 5px;
}

.microcycle-header-container {
  margin: 20px 0 0 0;

  .microcycle-client {
    background-color: $shadow;
    overflow: hidden;
    font-size: larger;
  }

  .toggle-group {
    display: flex;
    flex-direction: column;

    .label {
      font-size: xx-small;
      white-space: nowrap;
    }
  }

  .column {
    margin: 0;
    padding: 0;

    .active-toggle {
      color: $primary;
      font-size: larger;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .header-btn-col {
    margin-left: 10px;
  }
}

.day-summaries-container {
  width: 100%;
  margin: 5px 0;

  .microcycle-id {
    display: inline;
    background-color: $secondary;
    color: white;
    margin-bottom: 0;
    padding: 4px 5px 6px 5px;
    border-radius: 5px 5px 0px 0px;
    font-size: 12px;
    font-weight: bold;
  }

  .current-micro {
    color: $primary;
  }

  .day-summaries {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $secondary;
    border-radius: 0 5px 5px 5px;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 1px;

    .day-card {
      background-color: white;
      flex: 1;
      margin: 0px 1px;
      overflow-x: hidden;
      position: relative;

      .day-table {
        width: 100%;

        th {
          background-color: $secondary;
          color: white;
          padding: 0 2px;
          position: sticky;
          top: 0;
        }

        th:nth-child(1) {
          border-radius: 5px 0 0 0;
        }

        th:nth-child(4) {
          border-radius: 0 5px 0 0;
        }

        td {
          padding: 2px;
        }

        td + td {
          border-left: 1px solid rgba(191, 188, 188, 0.8);
        }
      }

      .day-table.selected th {
        background-color: $primary;
      }
    }

    .day-card.untoggle {
      max-height: 130px;
      overflow-y: overlay;
    }
  }

  .day-reorder-btn-left {
    transform: rotate(-90deg);
    color: $primary;
    margin: 0 20px;
    cursor: pointer;
  }

  .day-reorder-btn-right {
    transform: rotate(90deg);
    color: $primary;
    margin: 0 20px;
    cursor: pointer;
  }
}

.day-summaries-container.toggle {
  max-height: 159px;
  margin: 8px 0 3px 0;
  width: 100%;

  .day-summaries {
    border-radius: 5px;
    width: 100%;
    overflow-x: overlay;
  }
}

.day-card.toggle {
  background-color: white;
  margin: 0px 1px;
  max-height: 151px;
  overflow-y: overlay;
  min-width: 260px;

  .day-table-toggle {
    width: 100%;

    th {
      background-color: $secondary;
      color: white;
      padding: 0 2px;
      position: sticky;
      top: 22px;
    }

    td {
      padding: 2px;
    }

    td + td {
      border-left: 1px solid rgba(191, 188, 188, 0.8);
    }
  }
  .day-table-toggle.selected th {
    background-color: $primary;
  }
}

.sri {
  white-space: nowrap;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  min-width: 15px;
}

.microcycle-id-toggle {
  display: block;
  background-color: $secondary;
  color: white;
  margin: 0;
  padding: 2px;
  font-size: 12px;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.current-summary {
  cursor: pointer;
}

.subtable-header {
  background-color: black;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  font-size: larger;
}

.current-day-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
  background-color: $shadow;

  .save {
    background-color: $shadow;
    color: $secondary;
  }

  .current-day-table {
    font-size: 12px;
    padding: 5px;
    text-align: center;

    .reorder-btn {
      width: 14px;
      height: 15px;
      cursor: pointer;
    }

    .priority:hover:after {
      content: "Priority Flag";
    }
    .priority::after {
      content: "P";
    }

    .super-set:hover:after {
      content: "Super Set";
    }

    .super-set::after {
      content: "SS";
    }

    .comment {
      max-width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }

    .comment:hover {
      max-width: 100px;
      word-wrap: break-word;
      white-space: normal;
      text-align: left;
    }

    Table thead {
      background-color: $secondary;
      color: white;
      border-collapse: collapse;

      th {
        padding: 3px;
        border: 0.8px solid #fff;
        border-bottom: none;
      }
    }

    Table tbody {
      border-collapse: collapse;

      th {
        padding: 3px;
        border: 0.8px solid #555;
      }

      th:first-child {
        border-left: none;
      }

      th:last-child {
        border-right: none;
      }
    }

    .table-button-group {
      width: 30px;

      .btn {
        background-color: white;
        border: 1px solid $primary;
        width: 25px;
        height: 25px;
        position: relative;

        img {
          position: absolute;
          top: 2px;
          left: 2px;
        }
      }
    }
  }
}

// micro-summary and current day table
.summary-table-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-width: 100%;
  margin-bottom: 5px;
  min-height: 280px;
  padding: 0;
  z-index: -100;
}

// -- Scroll bar -- //
.day-card::-webkit-scrollbar {
  width: 5px;
}

.day-card::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.day-card::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.day-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.day-summaries::-webkit-scrollbar {
  height: 12px;
}

.day-summaries::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.day-summaries::-webkit-scrollbar-thumb {
  background: rgba(191, 188, 188, 0.4);
  border-radius: 5px;
}

.day-summaries::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// -- Responsive page -- //
@include media-breakpoint-down(md) {
  .summary-table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
    padding: 0;
  }
  .micro-summary-container {
    margin-bottom: 5px;
  }

  .current-day-container {
    width: 100%;
    margin: 0;
  }

  .left-btn {
    top: 80px;
    left: -22px;
  }

  .right-btn {
    top: 80px;
    right: 9px;
  }
}

@include media-breakpoint-down(sm) {
  .left-btn {
    top: 110px;
    left: -22px;
  }

  .right-btn {
    top: 115px;
    right: 9px;
  }
}
