/* The following block can be included in a custom.scss */

$primary: #b01212;
$secondary: #000000;
$shadow: #eeeeee;
$grey: #d9d9d9;

/* make the customizations */
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "grey": $grey,
);

/* Navigation */
// $nav-link-font-weight: bold;
$navbar-light-color: white;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
