.program-main-container {
  padding: 10px 50px;
}

.search-program-input::placeholder {
  color: #434343;
  font-weight: bold;
}

.search-program-input {
  color: #000;
  font-weight: bold;
}

@include media-breakpoint-down(md) {
  .program-main-container {
    padding: 5px;
  }
}
