.login {
    background-color: rgb(214, 4, 4);
    color: white;
    box-shadow: rgb(214, 4, 4);
    border-radius: 15px;
    display: flex;
}
.title-card {
    background-color: black;
    color: white;
    border-radius: 15px;
    width: 1400px;
    max-width: 1400px;
}
.login-card {
    background-color: black;
    color: white;
    border-radius: 15px;
}
.pg-bg {
    background-color: black;
}

.block-button {
    display: block;
    width: 100%;
}

.background {
    background-color: brown;
}

.forgot-password {
    display: block;
    margin-top: 10px;
    color: white;
    text-decoration: none;
    text-align: center;
}
