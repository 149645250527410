.modal-90w {
  min-width: 90%;
  margin-top: 80px;
  max-height: 80%;
}

.program-info {
  margin-top: 5px;
  padding: 0px 10px;

  .label {
    font-weight: bold;
    margin-right: 3px;
  }

  .info {
    margin-right: 10px;
  }
}

hr {
  margin-top: 0px;
  border-bottom: 1px solid black;
  margin-bottom: 0px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.modal-btn {
  width: 250px;
  font-size: 15px;
  font-weight: bold;
}

@include media-breakpoint-down(md) {
  .modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
